@use 'libs/heartbeat/src/lib/assets/scss/hb-foundations/index' as *;

.only-print {
  display: none;
}

@media print {
  @page {
    margin: 0;
  }

  body {
    print-color-adjust: exact;
    background-color: $neutral-0;
  }

  app-root,
  .no-print {
    display: none;
  }

  .only-print {
    display: initial;
  }
}
