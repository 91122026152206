@use 'sass:list';
@use 'sass:map';

@use '@cdl/heartbeat-tokens/dist/holter' as *;
@use './colors.scss' as *;

// Fonts: https://zeroheight.com/94b85b49d/p/078642-typography

// Default font family
$font-family: map.get($fonts, 'family');
$font-family-brand: map.get($fonts, 'family-brand');

// Font scales
$font-scale-xxs: map.get($fonts-size, 'xxs'); // 10px
$font-scale-xs: map.get($fonts-size, 'xs'); // 12px
$font-scale-s: map.get($fonts-size, 's'); // 14px
$font-scale-m: map.get($fonts-size, 'm'); // 16px
$font-scale-l: map.get($fonts-size, 'l'); // 20px
$font-scale-xl: map.get($fonts-size, 'xl'); // 24px
$font-scale-xxl: map.get($fonts-size, 'xxl'); // 32px

// Line heights
$line-height-standard: map.get($fonts-lineHeight, 'standard');
$line-height-subtitle: 1.284;
$line-height-secondary: map.get($fonts-lineHeight, 'secondary');

// Weights
$weight-regular: map.get($fonts-weight, 'regular');
$weight-semibold: map.get($fonts-weight, 'semibold');
$weight-bold: map.get($fonts-weight, 'bold');

// Helper to loop over font properties and apply them
@mixin applyTypographyStyle($map) {
  @each $prop, $value in $map {
    #{$prop}: $value;
  }
}

/// Typography mixin
///
/// @param {number} $weight
/// @param {number} $scale
/// @param {number} $lineHeight
@mixin typography($weight, $scale, $lineHeight) {
  font: normal $weight list.slash($scale, $scale * $lineHeight) $font-family;
}

@mixin main-title() {
  @include applyTypographyStyle($typography-common-main-title);
  color: $neutral-900;
}

@mixin page-title() {
  @include applyTypographyStyle($typography-common-page-title);
  color: $neutral-900;
}

@mixin section-title() {
  @include applyTypographyStyle($typography-common-section-title);
  color: $neutral-900;
}

@mixin title() {
  @include applyTypographyStyle($typography-common-title);
  color: $neutral-900;
}

@mixin subtitle() {
  @include applyTypographyStyle($typography-common-subtitle);
  color: $neutral-900;
}

@mixin item-list-title() {
  @include applyTypographyStyle($typography-common-item-list-title);
  color: $neutral-900;
}

@mixin main-text() {
  @include applyTypographyStyle($typography-common-main-text);
  color: $neutral-900;
}

@mixin main-text-bold() {
  @include applyTypographyStyle($typography-common-main-text-bold);
  color: $neutral-900;
}

@mixin main-text-semibold() {
  @include typography(
    $weight-semibold,
    // 700
    $font-scale-xs,
    // 12px
    $line-height-standard // 18px
  );

  color: $neutral-900;
}

@mixin secondary-text() {
  @include applyTypographyStyle($typography-common-secondary-text);
  color: $neutral-600;
}

@mixin label() {
  @include applyTypographyStyle($typography-common-label);
  color: $neutral-900;
}

@mixin label-secondary() {
  @include applyTypographyStyle($typography-common-secondary-label);
  color: $neutral-900;
}
