@use 'layout' as *;
@use 'text' as *;
@use 'libs/heartbeat/src/lib/assets/scss/hb-foundations' as *;

.dropdown-container {
  $triangle-size: 0.9rem;
  $content-bg: $neutral-0;

  position: absolute;
  display: block;

  z-index: 90;
  margin: $triangle-size $triangle-size 0 0;
  background: $content-bg;

  border: solid $border-xxs $neutral-100;
  border-radius: $radius-s;

  &:before {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    display: block;
    top: (-$triangle-size);
    left: auto;
    bottom: auto;
    right: 0.7rem;
    border-width: 0 $triangle-size $triangle-size;
    border-color: $neutral-100 transparent;
  }

  &__item {
    @extend .label;
    @extend .label_light_secondary;

    padding: $padding-s $padding-m;
    cursor: pointer;
    min-width: 10rem;
    border-bottom: $border-xxs solid $neutral-100;

    &:hover {
      box-shadow: $border-xs 0 0 0 $neutral-300;
      background: $neutral-25;
    }
  }
}
