@use './colors' as *;

@mixin base-disabled-state() {
  cursor: not-allowed;
  background-color: $neutral-50;
}

@mixin wrapper-disabled-state() {
  @include base-disabled-state();
  border: 0.1rem solid $neutral-200;
}

@mixin native-form-control-disabled-state() {
  @include base-disabled-state();
  color: $neutral-300;
}
