@use 'libs/heartbeat/src/lib/assets/scss/hb-foundations/colors' as *;
@use '../styleguide/layout' as *;
@use '../styleguide/text' as *;
@use '../styleguide/icons' as *;

// Typography
$headings-font-weight: $font-weight-semi-bold;

// Annotate: style variables.
// prev black = #2F3337;
$icon-size: 1.6rem;
$icon-size-s: 1.3rem;
$icon-size-xs: 1rem;
$logo-size: 3rem;
$navbar-size: 3rem;
$asidebar-size: 3rem;

// Cdl dimensions
$break-sm: 30rem;
$break-md: 75rem;
$break-lg: 110rem;

$aside-width: 33rem;
$leftbar-size: 16rem; // deprecated: moved to styleguide/layout.scss
$leftbar-left-padding: 0.3rem;
$leftbar-title-size: 1rem;
$leftbar-title-weight: 400;
$header-height: 3rem;
$header-height-border: 0.1rem;
$body-height: calc(100vh - #{$header-height});
$body-height-small: calc(100vh - #{$header-height});
$cdl-padding-xsmall: 0.4rem;
$cdl-padding-small: 0.7rem;
$cdl-padding-default: 1.4rem;
$cdl-padding-large: 3rem;
$cdl-margin-xsmall: $cdl-padding-xsmall;
$cdl-margin-small: $cdl-padding-small;
$cdl-margin-default: $cdl-padding-default;
$cdl-margin-large: $cdl-padding-large;

//Font colors
$th-font-primary: 'Open Sans', sans-serif;

$th-border-radius: 0.3rem;
$th-semi-bold: 600;

// RD Colors
$cdl-rd-wave-P: #1d31cc; // No matching color
$cdl-rd-wave-QRS: #ff4e0b; // No matching color
$cdl-rd-wave-T: #5dc31e; // No matching color
$cdl-rd-wave-INV: #ffcf71; // No matching color

// Default colors
$cdl-wave-P: #5499c3; // closest: $primary-600
$cdl-wave-QRS: #ffcf71; // closest: $yellow-200
$cdl-wave-T: #87b62d; // closest: $green-300
$cdl-wave-INV: #dbd9d6; // closest: $neutral-100

$cdl-action-primary: #4a90e2; // No matching color
$cdl-primary: #004f94; // logo blue // No matching color

$color_blue: #113355; // closest: $neutral-700
$brand-warning: #fc7c21; // closest: $yellow-500
$brand-danger: #e9373f; // closest: $red-300

//Resting Theme
$cdl-grid-width: 0.8;
$cdl-lead-width: 1;
$cdl-rd-wave-opacity: 0.2;
$cdl-rd-wave-manual: 0.5;
$cdl-wave-opacity: 0.4;
$cdl-wave-manual: 0.7;
$cdl-wave-opacity-light: 0.2;
$cdl-wave-opacity: 0.4;
$cdl-timeslider-border: transparent;

//Laddergram colors

$cdl-laddergram-P-color: $cdl-wave-P;
$cdl-laddergram-QRS-color: $cdl-wave-QRS;

//ECG Cards

$cdl-ecg-card-width: 46rem;

//Placeholders

%override-outline {
  box-shadow: inset 0 0 0.2rem 0 $primary-300;
  border: $border-xs solid $primary-300;

  outline-color: transparent; // remove os-outline
  outline-style: none;
}
