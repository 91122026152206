@use '../config/variables' as *;
@use 'libs/heartbeat/src/lib/assets/scss/hb-foundations' as *;
@use 'layout' as *;

$arrow-size: 0.3rem;
$arrow-before-size: 0.6rem;

.select {
  &_light {
    position: relative;
    display: inline-block;
    width: 100%;

    & > select {
      color: $neutral-700;
      display: block;

      width: 100%;
      padding: ($margin-s + 0.1rem);
      margin: 0;

      background-color: $neutral-0;
      border: $border-xxs solid $neutral-100;
      border-radius: $radius-m;

      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;

      transition: border-color 0.2s;

      & > option {
        margin: $padding-m;
        color: $neutral-700;
      }
    }
    & > .arrow {
      bottom: $arrow-size;
      position: absolute;
      right: $arrow-size;
      top: $arrow-size;
      width: 1.8rem;
      pointer-events: none;
      cursor: pointer;
    }
    & > .arrow:before {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: (-$arrow-size);
      pointer-events: none;
      border: $arrow-before-size solid $neutral-300;
      border-bottom: 0;
      border-left-color: transparent;
      border-right-color: transparent;
    }
    & > .arrow:after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: (-$arrow-size);
      pointer-events: none;
      border: $arrow-size solid $neutral-300;
      border-bottom: 0;
      border-left-color: transparent;
      border-right-color: transparent;
    }
    & > .arrow:hover:before,
    & > .arrow:hover:after {
      border-top-color: $neutral-0;
    }
  }

  &_dark {
    position: relative;
    display: inline-block;
    width: 100%;

    & > select {
      display: block;

      width: 100%;
      padding: ($padding-s + 0.1rem);
      height: 3rem;
      margin: 0;

      border: $border-xs solid $neutral-900;
      border-radius: $radius-s;
      background-color: $neutral-600;
      color: $neutral-25;
      cursor: pointer;

      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;

      transition: border-color 0.2s;

      & > option {
        margin: $padding-m;
        color: $neutral-100;
      }
    }
    & > .arrow {
      bottom: $arrow-size;
      position: absolute;
      right: $arrow-size;
      top: $arrow-size;
      width: 1.8rem;
      pointer-events: none;
    }
    & > .arrow:before {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: (-$arrow-size);
      pointer-events: none;
      border: $arrow-before-size solid $neutral-300;
      border-bottom: 0;
      border-left-color: transparent;
      border-right-color: transparent;
    }
    & > .arrow:after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: (-$arrow-size);
      pointer-events: none;
      border: $arrow-size solid $neutral-300;
      border-bottom: 0;
      border-left-color: transparent;
      border-right-color: transparent;
    }
    &:hover > .arrow:before,
    &:hover > .arrow:after {
      border-top-color: $neutral-0;
    }
  }
}
