.flex {
  display: flex;
  &_full {
    flex: 1;
  }
  &__row {
    flex-direction: row;
    &_space-between {
      justify-content: space-between;
    }
    &_space-around {
      justify-content: space-around;
    }
    &_space-evenly {
      justify-content: space-evenly;
    }
    &_horizontal {
      &_center {
        justify-content: center;
      }
      &_start {
        justify-content: flex-start;
      }
      &_end {
        justify-content: flex-end;
      }
    }
    &_vertical {
      &_center {
        align-items: center;
      }
      &_start {
        align-items: flex-start;
      }
      &_end {
        align-items: flex-end;
      }
      &_baseline {
        align-items: baseline;
      }
      &_self {
        &_center {
          align-self: center;
        }
        &_start {
          align-self: flex-start;
        }
        &_end {
          align-self: flex-end;
        }
      }
      &_around {
        align-content: space-around;
      }
    }
  }
  &__column {
    flex-direction: column;
    &_space-between {
      justify-content: space-between;
    }
    &_space-around {
      justify-content: space-around;
    }
    &_space-evenly {
      justify-content: space-evenly;
    }
    &_vertical {
      &_center {
        justify-content: center;
      }
      &_start {
        justify-content: flex-start;
      }
      &_end {
        justify-content: flex-end;
      }
    }
    &_horizontal {
      &_center {
        align-items: center;
      }
      &_start {
        align-items: flex-start;
      }
      &_end {
        align-items: flex-end;
      }
      &_baseline {
        align-items: baseline;
      }
      &_self {
        &_center {
          align-self: center;
        }
        &_start {
          align-self: flex-start;
        }
        &_end {
          align-self: flex-end;
        }
      }
    }
  }
  &_wrap {
    flex-wrap: wrap;
  }
  &__direction {
    &_row {
      flex-direction: row;
    }
    &_column {
      flex-direction: column;
    }
  }
  &_inline-flex {
    display: inline-flex;
  }
}
