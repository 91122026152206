//this file represents all the variables used across other .scss files.

// Widths
$width-col: 30rem;

// Borders
$border-xxs: 0.05rem; // 1px (similar to)
$border-xs: 0.1rem; // 1px (similar to)
$border-s: 0.2rem; // 2px
$border-m: 0.4rem; // 4px
$border-l: 0.7rem; // 8px

// Border radius
$radius-xs: 0.1rem; // 1px (similar to)
$radius-s: 0.2rem; // 2px
$radius-m: 0.4rem; // 4px
$radius-l: 0.7rem; // 8px

// Padding & Margins
$padding-xxs: 0.2rem; // 2px
$padding-xs: 0.4rem; // 4px
$padding-s: 0.7rem; // 8px
$padding-sm: 1rem; // 12px
$padding-ms: 1.2rem; // 14px
$padding-m: 1.4rem; // 16px
$padding-ml: 1.8rem; // 20px
$padding-lm: 2.4rem; // 28px
$padding-l: 2.8rem; // 32 px
$padding-xl: 5.6rem; // 64 px
$padding-xxl: 11.2rem; // 128 px
// strips
$padding-episode: 12.2%;
$padding-episode-morphology: 16.5%;

$margin-xxs: 0.2rem; // 2px
$margin-xs: 0.4rem; // 4px
$margin-s: 0.7rem; // 8px
$margin-sm: 1rem; // 12px
$margin-ms: 1.2rem; // 14px
$margin-m: 1.4rem; // 16px
$margin-ml: 1.8rem; // 20px
$margin-lm: 2.4rem; // 28px
$margin-l: 2.8rem; // 32px
$margin-xl: 5.6rem; // 64 px
$margin-xxl: 11.2rem; // 128 px

$size-xxs: 0.2rem; // 2px
$size-xs: 0.4rem; // 4px
$size-s: 0.7rem; // 8px
$size-sm: 1rem; // 12px
$size-ms: 1.2rem; // 14px
$size-m: 1.4rem; // 16px
$size-ml: 1.8rem; // 20px
$size-lm: 2.4rem; // 28px
$size-l: 2.8rem; // 32px
$size-xl: 5.6rem; // 64 px
$size-xxl: 11.2rem; // 128 px

// leftbar
$leftbar-size: 16rem;
