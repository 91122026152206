@use 'sass:map';
// this file represents all text shapes used in the style guide
@use 'libs/heartbeat/src/lib/assets/scss/hb-foundations' as *;

// Font sizes
$font-xxs: 0.8rem; // 9px
$font-xs: 0.9rem; // 10px
$font-s: 1rem; // 11px
$font-m: 1.1rem; // 12px
$font-l: 1.4rem; // 14px
$font-xl: 2rem; // 24px
$font-xxl: 3.2rem; // 36px (similar to)

// Default font family
$font-family: 'Open Sans', sans-serif;

// Font weights
$font-weight-thin: 300;
$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$title-font-size: 1.2rem;

$font-sizes: (
  xxs: (
    rem: $font-xxs,
    px: 9px,
  ),
  xs: (
    rem: $font-xs,
    px: 10px,
  ),
  s: (
    rem: $font-s,
    px: 11px,
  ),
  m: (
    rem: $font-m,
    px: 12px,
  ),
  l: (
    rem: $font-l,
    px: 14px,
  ),
  xl: (
    rem: $font-xl,
    px: 24px,
  ),
  xxl: (
    rem: $font-xxl,
    px: 36px,
  ),
);

$font-weights: (
  thin: $font-weight-thin,
  regular: $font-weight-regular,
  semi-bold: $font-weight-semi-bold,
  bold: $font-weight-bold,
);

@function get-font-size($size) {
  @if (map.has-key($font-sizes, $size)) {
    @return map.get($font-sizes, $size);
  } @else {
    @error 'There is no font-size matching "#{$size}", available values are: #{map-keys($font-sizes)}. You can view styleguide at https://staging.cardiologs.com/storybook to preview available values.';
  }
}

@function get-font-weight($weight) {
  @if (map.has-key($font-weights, $weight)) {
    @return map.get($font-weights, $weight);
  } @else {
    @error 'There is no font-weight matching "#{$weight}", available values are: #{map-keys($font-weights)}. You can view styleguide at https://staging.cardiologs.com/storybook to preview available values.';
  }
}

// * This mixins aims at helping create font config quickly
// *
// * Usage: @include font(size, weight, style)
// * Example: @include(xl, bold, italic);

@mixin font($size, $weight: regular, $style: normal) {
  font-family: $font-family;
  font-size: map.get(get-font-size($size), rem);
  font-weight: get-font-weight($weight);
  font-style: $style;

  // TODO: custom line-height or dedicated one per size ?
}

// * This mixins works as "font" but will return values in pixels
// * IMPORTANT NOTE : this should be used for SVG only !!
// *
// * Usage: @include font(size, weight, style)
// * Example: @include(xl, bold, italic);

@mixin font-in-pixels($size, $weight: regular, $style: normal) {
  @include font($size, $weight, $style);
  font-size: map.get(map.get($font-sizes, $size), px);

  // TODO: custom line-height or dedicated one per size ?
}

// Preconfigured texts

// Light primary
@mixin light-primary($size, $weight) {
  color: $neutral-900;
  @include font($size, $weight);
}

.label_light_primary {
  @include light-primary(s, semi-bold);
}
.label_light_primary_xxl {
  @include light-primary(xxl, semi-bold);
}
.label_light_primary_big {
  @include light-primary(l, semi-bold);
}
.label_light_primary_small {
  @include light-primary(xs, semi-bold);
}

// Light secondary
@mixin light-secondary($size, $weight) {
  color: $neutral-700;
  @include font($size, $weight);
}

.label_light_secondary {
  @include light-secondary(s, regular);
}
.label_light_secondary_xxl {
  @include light-secondary(m, semi-bold);
}
.label_light_secondary_big {
  @include light-secondary(s, semi-bold);
}
.label_light_secondary_small {
  @include light-secondary(xs, regular);
}
.label_light_secondary_xsmall {
  @include light-secondary(xxs, regular);
}

// Dark primary
@mixin dark-primary($size, $weight) {
  color: $neutral-25;
  @include font($size, $weight);
}

.label_dark_primary {
  @include dark-primary(s, semi-bold);
}
.label_dark_primary_xxl {
  @include dark-primary(xxl, semi-bold);
}
.label_dark_primary_big {
  @include dark-primary(l, semi-bold);
}
.label_dark_primary_small {
  @include dark-primary(xs, semi-bold);
}

// Dark secondary
@mixin dark-secondary($size, $weight) {
  color: $neutral-100;
  @include font($size, $weight);
}

.label_dark_secondary {
  @include dark-secondary(xs, regular);
}
.label_dark_secondary_xxl {
  @include dark-secondary(m, semi-bold);
}
.label_dark_secondary_big {
  @include dark-secondary(s, semi-bold);
}
.label_dark_secondary_small {
  @include dark-secondary(xs, regular);
}
.label_dark_secondary_xsmall {
  @include dark-secondary(xxs, regular);
}

.label {
  &_link {
    text-decoration: underline;
    cursor: pointer;
  }

  &_no-select {
    -webkit-user-select: none; // Chrome/Safari
    -moz-user-select: none; // Firefox
    -ms-user-select: none; // IE10+

    // Rules below not implemented in browsers yet
    -o-user-select: none;
    user-select: none;
  }

  &_danger {
    color: $red-500;
  }

  &_select {
    color: $primary-300;
  }

  &_italic {
    font-style: italic;
  }

  &_bold {
    font-weight: $font-weight-semi-bold;
  }

  &_center {
    text-align: center;
  }

  &_left {
    text-align: left;
  }

  &_right {
    text-align: right;
  }
}
