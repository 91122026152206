@use 'sass:map';

@use '@cdl/heartbeat-tokens/dist/holter' as *;
// Grid & spaces: https://zeroheight.com/94b85b49d/p/773a1b-grid--spaces

// Layout spaces
$space-xxs: map.get($spacing, 'xxs'); // 2px
$space-xs: map.get($spacing, 'xs'); // 4px
$space-s: map.get($spacing, 's'); // 8px
$space-m: map.get($spacing, 'm'); // 16px
$space-l: map.get($spacing, 'l'); // 24px
$space-xl: map.get($spacing, 'xl'); // 32px
$space-xxl: map.get($spacing, 'xxl'); // 64px
$space-2xxl: map.get($spacing, '2xxl'); // 128 px

@mixin border($color) {
  border: 0.01rem solid $color;
}
