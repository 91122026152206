$breakpoints: (
  sm: 52.36rem,
  md: 69.82rem,
  lg: 90.18rem,
  xl: 116.36rem,
  2xl: 139.64rem,
);
$colors-neutral: (
  0: #ffffff,
  25: #f7f7f7,
  50: #e8e8e8,
  100: #d1d1d1,
  200: #b7b7b7,
  300: #a3a3a3,
  400: #8f8f8f,
  500: #757575,
  600: #5c5c5c,
  700: #4d4d4d,
  800: #3d3d3d,
  900: #15191e,
);
$colors-primary: (
  25: #f0f9ff,
  50: #d1ecff,
  100: #9ed5ff,
  200: #70bfff,
  300: #3da8ff,
  400: #1492ff,
  500: #0072db,
  600: #0059b3,
  700: #004a99,
  800: #003b7a,
  900: #002b5c,
);
$colors-green: (
  25: #f0f8e8,
  50: #c8e5b5,
  100: #addd88,
  200: #ace67f,
  300: #95de64,
  400: #73d13d,
  500: #52c41a,
  600: #389e0d,
  700: #237804,
  800: #135200,
  900: #092b00,
);
$colors-yellow: (
  15: #fffcf2,
  25: #fff8de,
  50: #ffefbb,
  100: #fee797,
  200: #fee286,
  300: #ffd666,
  400: #ffc53d,
  500: #faad14,
  600: #d48806,
  700: #ad6800,
  800: #874d00,
  900: #613400,
);
$colors-red: (
  15: #fff5f5,
  25: #ffdede,
  50: #ffccc7,
  100: #ffb5ad,
  200: #ffa39e,
  300: #ff8175,
  400: #ff5c4d,
  500: #ca1100,
  600: #bb1f11,
  700: #a8071a,
  800: #820014,
  900: #5c0011,
);
$colors-ambulatory-brown: (
  50: #cbbcb1,
  500: #a38570,
);
$colors-ambulatory-salmon: (
  50: #f9c0ac,
  500: #ff8d66,
);
$colors-ambulatory-pink: (
  50: #efbae0,
  500: #eb82ce,
);
$colors-ambulatory-purple: (
  50: #bbc6f9,
  500: #849aff,
);
$colors-ambulatory-blue: (
  50: #abdbf9,
  500: #64c4ff,
);
$colors-ambulatory-aqua: (
  50: #80e6da,
  500: #00cdb5,
);
$colors-brand-intenseBlue: (
  300: #e0e9f5,
  400: #c8d4e6,
  500: #adbfd9,
);
$colors-brand-blue: (
  400: #c7ddff,
  500: #99c1ff,
  600: #6996db,
  700: #38609c,
  800: #14386e,
);
$colors-brand-green: (
  400: #a8ebc1,
  500: #82d9a1,
  600: #59c280,
  700: #2e9955,
  800: #1d8242,
  900: #175e31,
);
$colors-brand-yellow: (
  400: #ffedb2,
  500: #ffde73,
  600: #ffd23d,
  700: #ffc60d,
  800: #e0b109,
  900: #c99a00,
);
$colors-brand-orange: (
  400: #ffbf9e,
  500: #ffa87a,
  600: #ff9459,
  700: #ed702e,
  800: #bd4200,
  900: #993600,
);
$fonts: (
  family: ('Open Sans', sans-serif),
  family-brand: ('Neue Frutiger One', sans-serif),
);
$fonts-size: (
  xxs: 0.91rem,
  xs: 1.09rem,
  s: 1.27rem,
  m: 1.45rem,
  l: 1.82rem,
  xl: 2.18rem,
  xxl: 2.91rem,
);
$fonts-lineHeight: (
  standard: 1.5,
  secondary: 1.25,
);
$fonts-weight: (
  regular: 400,
  semibold: 600,
  bold: 700,
);
$fonts-styles-page-title: (
  font-family: ('Open Sans', sans-serif),
  font-size: 2.91rem,
  font-weight: 700,
  line-height: 1.25,
);
$fonts-styles-page-subtitle: (
  font-family: ('Open Sans', sans-serif),
  font-size: 1.82rem,
  font-weight: 400,
  line-height: 1.2,
);
$fonts-styles-section-title: (
  font-family: ('Open Sans', sans-serif),
  font-size: 2.18rem,
  font-weight: 700,
  line-height: 1.33,
);
$fonts-styles-section-subtitle: (
  font-family: ('Open Sans', sans-serif),
  font-size: 1.45rem,
  font-weight: 400,
  line-height: 1.25,
);
$fonts-styles-category-title: (
  font-family: ('Open Sans', sans-serif),
  font-size: 1.82rem,
  font-weight: 700,
  line-height: 1.6,
);
$fonts-styles-category-subtitle: (
  font-family: ('Open Sans', sans-serif),
  font-size: 1.27rem,
  font-weight: 400,
  line-height: 1.14,
);
$fonts-styles-label: (
  font-family: ('Open Sans', sans-serif),
  font-size: 1.45rem,
  font-weight: 600,
  line-height: 1.25,
);
$fonts-styles-paragraph: (
  font-family: ('Open Sans', sans-serif),
  font-size: 1.45rem,
  font-weight: 400,
  line-height: 1.5,
);
$fonts-styles-small-label: (
  font-family: ('Open Sans', sans-serif),
  font-size: 1.27rem,
  font-weight: 600,
  line-height: 1.14,
);
$fonts-styles-subtitle: (
  font-family: ('Open Sans', sans-serif),
  font-size: 1.27rem,
  font-weight: 600,
  line-height: 1.28,
);
$shadow: (
  200: 0.00rem 0.09rem 0.09rem 0.00rem #0000001a,
  400: (0.00rem 0.09rem 0.27rem 0.00rem #0000001f, 0.00rem 0.09rem 0.18rem 0.00rem #00000040),
  500: (0.00rem 0.18rem 0.36rem 0.00rem #0000001f, 0.00rem 0.27rem 0.55rem 0.00rem #00000026),
  600: (0.00rem 0.27rem 0.55rem 0.00rem #0000001a, 0.00rem 0.91rem 1.82rem 0.00rem #00000026),
  top500: (0.00rem -0.18rem 0.36rem 0.00rem #0000001f, 0.00rem -0.27rem 0.55rem 0.00rem #00000026),
  top400: (0.00rem -0.09rem 0.27rem 0.00rem #0000001f, 0.00rem -0.09rem 0.18rem 0.00rem #00000040),
  focus-visible-shadow: 0.00rem 0.00rem 0.00rem 0.36rem #9ed5ffff,
  invalid-focus-visible-shadow: 0.00rem 0.00rem 0.00rem 0.36rem #ffb5adff,
);
$spacing: (
  xxs: 0.18rem,
  xs: 0.36rem,
  s: 0.73rem,
  m: 1.45rem,
  l: 2.18rem,
  xl: 2.91rem,
  xxl: 5.82rem,
  2xxl: 11.64rem,
);
$typography-common-main-title: (
  font-size: 2.91rem,
  text-decoration: none,
  font-family: Neue Frutiger One,
  font-weight: 700,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 3.64rem,
  text-indent: 0.00rem,
  text-transform: uppercase,
);
$typography-common-page-title: (
  font-size: 2.18rem,
  text-decoration: none,
  font-family: Neue Frutiger One,
  font-weight: 700,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 2.73rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-common-section-title: (
  font-size: 1.82rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 700,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 2.27rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-common-title: (
  font-size: 1.45rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 700,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 1.82rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-common-label: (
  font-size: 1.45rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 400,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 1.82rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-common-subtitle: (
  font-size: 1.27rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 600,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 1.64rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-common-secondary-label: (
  font-size: 1.27rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 400,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 1.45rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-common-main-text: (
  font-size: 1.09rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 400,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 1.64rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-common-main-text-bold: (
  font-size: 1.09rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 700,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 1.64rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-common-item-list-title: (
  font-size: 0.91rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 600,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 1.09rem,
  text-indent: 0.00rem,
  text-transform: uppercase,
);
$typography-common-secondary-text: (
  font-size: 0.91rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 400,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 1.27rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-react-paragraph: (
  font-size: 1.45rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 400,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 2.18rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-react-label: (
  font-size: 1.45rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 600,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 1.82rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-react-small-label: (
  font-size: 1.27rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 600,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 1.45rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-react-subtitle-small-label: (
  font-size: 1.27rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 400,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 1.45rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-react-page-title: (
  font-size: 2.91rem,
  text-decoration: none,
  font-family: Neue Frutiger One,
  font-weight: 700,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 3.64rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-react-page-subtitle: (
  font-size: 1.82rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 400,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 2.18rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-react-section-title: (
  font-size: 2.18rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 700,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 2.91rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-react-section-subtitle: (
  font-size: 1.45rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 400,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 1.82rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-react-category-title: (
  font-size: 1.82rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 700,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 2.91rem,
  text-indent: 0.00rem,
  text-transform: none,
);
$typography-react-category-subtitle: (
  font-size: 1.27rem,
  text-decoration: none,
  font-family: Open Sans,
  font-weight: 400,
  font-style: normal,
  font-stretch: normal,
  letter-spacing: 0.00rem,
  line-height: 1.45rem,
  text-indent: 0.00rem,
  text-transform: none,
);