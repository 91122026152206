@use 'sass:map';

@use '@cdl/heartbeat-tokens/dist/holter' as *;

/**
 *
 * @param {String} $breakpoint - Heartbeat breakpoint (e.g., 'sm', 'md', etc.). See heartbeat token repository
 * @param {String} [$type=min] - Accepted values: 'min' (default) or 'max'.
 * @param {Boolean} [$screen=false] - Include the 'screen' condition in the media query. Default is `false`.
 *
 * @example
 * @include respond-to('sm') { ..content } // @media (min-width: 576px)
 * @include respond-to('sm', max) { ...content } // @media (max-width: 576px)
 * @include respond-to('sm', max, true) { ...content } // @media screen and (max-width: 576px)
 */
@mixin respond-to($breakpoint, $type: min, $screen: false) {
  // If the key exists in the map
  @if map.has-key($breakpoints, $breakpoint) {
    $media-query: null;

    @if $type == min {
      $media-query: if(
        $screen,
        'screen and (min-width: #{map.get($breakpoints, $breakpoint)})',
        '(min-width: #{map.get($breakpoints, $breakpoint)})'
      );
    } @else if $type == max {
      $media-query: if(
        $screen,
        'screen and (max-width: #{map.get($breakpoints, $breakpoint)})',
        '(max-width: #{map.get($breakpoints, $breakpoint)})'
      );
    }

    @media #{$media-query} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
