@use 'libs/core-styles/src/lib/index' as *;
@use 'libs/heartbeat/src/lib/assets/scss/hb-foundations' as *;

.on-highlight_active {
  z-index: 3000;
  position: relative;
}

#onboarding-ventricular-rhythm-diagnostic-template-page,
#onboarding-ventricular-rhythm-analysis-page-category {
  background-color: $neutral-0;
  box-shadow: 0 0 0 $space-xs $neutral-0;
  border-radius: $radius-s;
}

.on-boarding_active {
  z-index: 3000 !important;
}

.highlight-bg_transparent {
  background-color: transparent !important;
}

.on-boarding_blue-highlight {
  border-radius: 0.6rem;
  border-style: double;
  outline: 0.5rem solid $primary-100;
  border: 0.2rem solid $primary-500;
}

.on-boarding_scale-down {
  transform: scale(0.9);
  transition: transform 0.8s ease;
}

.on-boarding_scale-initial {
  transform: scale(1);
  transition: transform 0.8s ease;
}
