@use 'sass:map';

@use '@cdl/heartbeat-tokens/dist/holter' as *;
/// COLORS: https://zeroheight.com/94b85b49d/p/00b02a-colors/b/1939a8

/// --- Primary colors ---

// Neutral
$neutral-900: map.get($colors-neutral, 900);
$neutral-800: map.get($colors-neutral, 800);
$neutral-700: map.get($colors-neutral, 700);
$neutral-600: map.get($colors-neutral, 600);
$neutral-500: map.get($colors-neutral, 500);
$neutral-400: map.get($colors-neutral, 400);
$neutral-300: map.get($colors-neutral, 300);
$neutral-200: map.get($colors-neutral, 200);
$neutral-100: map.get($colors-neutral, 100);
$neutral-50: map.get($colors-neutral, 50);
$neutral-25: map.get($colors-neutral, 25);
$neutral-0: map.get($colors-neutral, 0);

// Primary
$primary-900: map.get($colors-primary, 900);
$primary-800: map.get($colors-primary, 800);
$primary-700: map.get($colors-primary, 700);
$primary-600: map.get($colors-primary, 600);
$primary-500: map.get($colors-primary, 500);
$primary-400: map.get($colors-primary, 400);
$primary-300: map.get($colors-primary, 300);
$primary-200: map.get($colors-primary, 200);
$primary-100: map.get($colors-primary, 100);
$primary-50: map.get($colors-primary, 50);
$primary-25: map.get($colors-primary, 25);

/// --- Secondary colors ---

// Red variations
$red-900: map.get($colors-red, 900);
$red-700: map.get($colors-red, 700);
$red-600: map.get($colors-red, 600);
$red-500: map.get($colors-red, 500);
$red-300: map.get($colors-red, 300);
$red-100: map.get($colors-red, 100);
$red-50: map.get($colors-red, 50);
$red-25: map.get($colors-red, 25);

// Green variations
$green-900: map.get($colors-green, 900);
$green-600: map.get($colors-green, 600);
$green-500: map.get($colors-green, 500);
$green-400: map.get($colors-green, 400);
$green-300: map.get($colors-green, 300);
$green-100: map.get($colors-green, 100);
$green-50: map.get($colors-green, 50);
$green-25: map.get($colors-green, 25);

// Yellow variations
$yellow-900: map.get($colors-yellow, 900);
$yellow-800: map.get($colors-yellow, 800);
$yellow-600: map.get($colors-yellow, 600);
$yellow-500: map.get($colors-yellow, 500);
$yellow-400: map.get($colors-yellow, 400);
$yellow-300: map.get($colors-yellow, 300);
$yellow-200: map.get($colors-yellow, 200);
$yellow-50: map.get($colors-yellow, 50);
$yellow-25: map.get($colors-yellow, 25);

/// --- Tertiary colors ---

// Brown variations
$brown-500: map.get($colors-ambulatory-brown, 500);
$brown-50: map.get($colors-ambulatory-brown, 50);

// Salmon variations
$salmon-500-500: map.get($colors-ambulatory-salmon, 500);
$salmon-500-50: map.get($colors-ambulatory-salmon, 50);

// Pink variations
$pink-500: map.get($colors-ambulatory-pink, 500);
$pink-50: map.get($colors-ambulatory-pink, 50);

// Purple variations
$purple-500: map.get($colors-ambulatory-purple, 500);
$purple-50: map.get($colors-ambulatory-purple, 50);

// Blue variations
$blue-500: map.get($colors-ambulatory-blue, 500);
$blue-50: map.get($colors-ambulatory-blue, 50);

// Aqua variations
$aqua-500: map.get($colors-ambulatory-aqua, 500);
$aqua-50: map.get($colors-ambulatory-aqua, 50);
