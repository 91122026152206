@use 'libs/heartbeat/src/lib/assets/scss/hb-foundations/colors' as *;
@use 'styleguide/layout' as *;
// Abnormality colors

@mixin abn-color($color, $light-color) {
  background-color: $color;
  border: solid 1px $color;
  &.light {
    background-color: $light-color;
    border: solid 1px $light-color;
  }
}

.cdl-abn-sinus {
  @include abn-color($aqua-500, $aqua-50);
}

.cdl-abn-st {
  @include abn-color($salmon-500-50, $salmon-500-50);
}

.cdl-abn-max {
  @include abn-color($yellow-300, $yellow-200);
}

.cdl-abn-min {
  @include abn-color($salmon-500-500, $salmon-500-50);
}

.cdl-abn-pause {
  @include abn-color($pink-500, $pink-50);
}

.cdl-abn-av {
  @include abn-color($purple-500, $purple-50);
}

.cdl-abn-afib {
  @include abn-color($blue-500, $blue-50);
}

.cdl-abn-other-attr {
  @include abn-color($green-500, $green-50);
}

.cdl-abn-vt {
  @include abn-color($brown-500, $brown-50);
}

.cdl-abn-pac {
  @include abn-color($aqua-500, $aqua-50);
}

.cdl-abn-pvc {
  @include abn-color($red-500, $red-50);
}

.cdl-abn-other {
  background-color: $yellow-400;
  border: solid $border-xxs $yellow-400;
  &.light {
    opacity: 0.5;
  }
}

.cdl-abn-pat {
  color: $red-500;
  &.light {
    color: $red-50;
  }
}

.cdl-abn-inv,
.cdl-abn-manual {
  @include abn-color($neutral-300, $neutral-200);
}
