// You can add global styles to this file, and also import other style files
// Configuration options

// core-styles variables & mixins - deprecated
@use 'libs/core-styles/src/lib/index' as *;

// core-styles global css styles - deprecated
@use 'libs/core-styles/src/lib/grid' as *;
@use 'libs/core-styles/src/lib/svg' as *;
@use 'libs/core-styles/src/lib/theme' as *;
@use 'libs/core-styles/src/lib/categories' as *;
@use 'libs/core-styles/src/lib/print' as *;
@use 'libs/core-styles/src/lib/flex-grid' as *;
@use 'libs/core-styles/src/lib/cdl-icon' as *;
@use 'libs/core-styles/src/lib/onboarding' as *;
@use 'libs/core-styles/src/lib/lib/hopscotch' as *;
@use 'libs/core-styles/src/lib/fonts' as *;
@use 'libs/core-styles/src/lib/styleguide/core' as *;
@use 'libs/core-styles/src/lib/styleguide/dropdown' as *;
@use 'libs/core-styles/src/lib/styleguide/select' as *;
@use 'libs/core-styles/src/lib/styleguide/theme' as *;
@use 'libs/core-styles/src/lib/input-range' as *;

// hearbeat tokens - preferred
@use 'libs/heartbeat/src/lib/assets/scss/hb-foundations' as *;

// https://material.angular.io/cdk/overlay/overview
@use '@angular/cdk/overlay-prebuilt.css' as *;

button,
select,
textarea,
input {
  font-family: $th-font-primary;
  font-weight: $font-weight-regular;
}

svg {
  background-color: transparent !important;
}

a {
  text-decoration: none;
  color: inherit;
}

[layout-fill] {
  height: 100%;
}

md-tooltip {
  font-size: 0.6em; // TODO: Non matching font
}

.transparent {
  background-color: transparent !important;
}

.an-full {
  height: 100%;
}

.an-visible {
  visibility: visible;
}

.an-hidden {
  visibility: hidden;
}

.an-link {
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  color: inherit;
  text-decoration: none;
}

.an-pointer {
  cursor: pointer;
}

.an-normal {
  cursor: default;
}

.an-input {
  margin-top: $margin-m;
  min-height: 3em;
}

.cdl-hidden {
  display: none;
}

.cdl-discrete {
  font-size: $font-xxs;
  opacity: 0.5;
}

.cdl-btn {
  font-size: $font-xxs;
}

.cdl-button {
  padding: ($cdl-padding-default * 0.5) $cdl-padding-default;
  text-align: center;
  border-radius: $radius-s;
  text-decoration: none;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;

  &.active {
    background-color: $cdl-action-primary;
    color: $neutral-0;
  }

  &.disabled {
    background-color: grey;
    color: $neutral-900;
    cursor: not-allowed;
  }
}

.cdl-btn-active {
  cursor: pointer;
  color: $cdl-action-primary;
}

.cdl-btn:hover {
  color: $cdl-action-primary;
  opacity: 1;
}

.cdl-divider-bottom {
  border-bottom: $border-xs solid $neutral-25;
}

.danger {
  color: $brand-danger;
}

.warning {
  color: $brand-warning;
}

.cdl-relative {
  position: relative;
}

.cdl-emergency {
  color: $red-500;
}

.cdl-significant {
  color: $yellow-500;
}

.cdl-nonsignificant {
  color: $primary-300;
}

//  shared by beats && interpret Reveal Modals

.reveal-modal-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: $neutral-900;
  background: rgba($neutral-900, 0.2);
  z-index: 100;
  display: none;
  top: 0;
  left: 0;
}

// Input

.cdl-input {
  box-shadow: none;
  border: none;
  border-bottom: solid $border-xs #c9c9c9; // TODO: no matching color
  transition: border 0.3s;
  min-width: 100%;
  background-color: #fff;
  margin-bottom: $margin-m;
}

.cdl-input:focus,
.cdl-input.focus {
  border-bottom: solid $border-xs $cdl-action-primary;
}

.cdl-padding {
  padding: $cdl-padding-default;
}

.cdl-text-constrain {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cdl-link {
  color: $cdl-action-primary;
  cursor: pointer;

  &:hover {
    color: $cdl-primary;
  }
}

.cdl-placeholder {
  flex: 1;

  & > .message {
    font-size: 2.5em; // TODO: non matching font
    line-height: 1.4em;
    // text-align: center;
    opacity: 0.4;
    padding: $margin-xl;
    margin: $padding-m;
  }
}

.cdl-scroll-loader-wrapper {
  min-height: 100%;
  overflow-x: hidden;
}

#js-ecg-graphs-signal {
  display: block;
  position: relative;
}

.research-body-left {
  svg {
    width: 100% !important;
    height: 100% !important;
  }
}

.highlight {
  color: black;
}

// `app-overlay-container` is a copy/paste of the css class `cdk-overlay-container`
// obtained by inspecting <div class="cdk-overlay-container"> for a dropdown in the dev tools

.app-overlay-container {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.worklist-page-ecg-card-labels-dropdown {
  // display the dropdown behind the delete label confirmation
  // modal
  z-index: 0 !important;
}
