@use "sass:map";

//  --------------------------------------------------
//  Font Icon for hb-icon
//  --------------------------------------------------
@font-face {
  font-family: "hb-icon-xs";
  src: url("../fonts/hb-icon-xs.ttf?4b08844569dc3886fa1c0ec5dbee1be5") format("truetype"),
url("../fonts/hb-icon-xs.woff?4b08844569dc3886fa1c0ec5dbee1be5") format("woff"),
url("../fonts/hb-icon-xs.svg?4b08844569dc3886fa1c0ec5dbee1be5#hb-icon-xs") format("svg");
  font-weight: normal;
  font-style: normal;
}

$hb-icon-xs-glyphs: (
  'add-episode': "\f101",
  'add-strip': "\f102",
  'add-to-report-check': "\f103",
  'add-to-report': "\f104",
  'add-user': "\f105",
  'alert-error': "\f106",
  'alert-info': "\f107",
  'alert-success': "\f108",
  'amplify': "\f109",
  'archive': "\f10a",
  'arrow-down': "\f10b",
  'arrow-left': "\f10c",
  'arrow-right': "\f10d",
  'arrow-up': "\f10e",
  'axes': "\f10f",
  'barcode': "\f110",
  'bell': "\f111",
  'blur-on': "\f112",
  'bookmark': "\f113",
  'buildings': "\f114",
  'calendar': "\f115",
  'category': "\f116",
  'change-duration': "\f117",
  'check': "\f118",
  'chevron-down': "\f119",
  'chevron-left': "\f11a",
  'chevron-right': "\f11b",
  'chevron-up': "\f11c",
  'close': "\f11d",
  'copy': "\f11e",
  'count': "\f11f",
  'cross': "\f120",
  'crossed-eye': "\f121",
  'detach-window': "\f122",
  'dots': "\f123",
  'download': "\f124",
  'duration': "\f125",
  'enlarge': "\f126",
  'episode-cluster-mode': "\f127",
  'extra-strip': "\f128",
  'eye': "\f129",
  'filters-off': "\f12a",
  'filters-on': "\f12b",
  'flag': "\f12c",
  'folder': "\f12d",
  'gear': "\f12e",
  'hidden-folder': "\f12f",
  'hollowbookmark': "\f130",
  'home': "\f131",
  'hospital': "\f132",
  'invert-signal': "\f133",
  'key': "\f134",
  'keyboard': "\f135",
  'label': "\f136",
  'letter': "\f137",
  'list-dots': "\f138",
  'list': "\f139",
  'loader': "\f13a",
  'magnifying-glass': "\f13b",
  'medical-bag': "\f13c",
  'merge': "\f13d",
  'minus': "\f13e",
  'moon': "\f13f",
  'multiple-files-user': "\f140",
  'notification': "\f141",
  'patient-activity': "\f142",
  'patient-age': "\f143",
  'patient-symptom': "\f144",
  'pen': "\f145",
  'pin': "\f146",
  'plus': "\f147",
  'printer': "\f148",
  'quality-control': "\f149",
  'question': "\f14a",
  'reclassify': "\f14b",
  'reduce': "\f14c",
  'reload': "\f14d",
  'remove-from-report': "\f14e",
  'remove-user': "\f14f",
  'report': "\f150",
  'revert': "\f151",
  'ruler': "\f152",
  'share': "\f153",
  'shared-orga': "\f154",
  'shrink': "\f155",
  'single-file-user': "\f156",
  'solid-drag': "\f157",
  'solid-pediatric': "\f158",
  'sort-down': "\f159",
  'sort-up': "\f15a",
  'sort': "\f15b",
  'split-denied': "\f15c",
  'split': "\f15d",
  'star-filled': "\f15e",
  'star': "\f15f",
  'status': "\f160",
  'stethoscope': "\f161",
  'sun': "\f162",
  'support': "\f163",
  'sync': "\f164",
  'time-indicator': "\f165",
  'trash': "\f166",
  'tunes': "\f167",
  'upload': "\f168",
  'user-circle': "\f169",
  'user': "\f16a",
  'warning': "\f16b",
  'zoom-area': "\f16c",
);

.hb-icon-xs {
  // use !important to prevent issues with browser extensions that change fonts
  font-family: "hb-icon-xs" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  // Better Font Rendering ===========
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.hb-glyph-add-episode:before {
    content: "\f101";
  }
  &.hb-glyph-add-strip:before {
    content: "\f102";
  }
  &.hb-glyph-add-to-report-check:before {
    content: "\f103";
  }
  &.hb-glyph-add-to-report:before {
    content: "\f104";
  }
  &.hb-glyph-add-user:before {
    content: "\f105";
  }
  &.hb-glyph-alert-error:before {
    content: "\f106";
  }
  &.hb-glyph-alert-info:before {
    content: "\f107";
  }
  &.hb-glyph-alert-success:before {
    content: "\f108";
  }
  &.hb-glyph-amplify:before {
    content: "\f109";
  }
  &.hb-glyph-archive:before {
    content: "\f10a";
  }
  &.hb-glyph-arrow-down:before {
    content: "\f10b";
  }
  &.hb-glyph-arrow-left:before {
    content: "\f10c";
  }
  &.hb-glyph-arrow-right:before {
    content: "\f10d";
  }
  &.hb-glyph-arrow-up:before {
    content: "\f10e";
  }
  &.hb-glyph-axes:before {
    content: "\f10f";
  }
  &.hb-glyph-barcode:before {
    content: "\f110";
  }
  &.hb-glyph-bell:before {
    content: "\f111";
  }
  &.hb-glyph-blur-on:before {
    content: "\f112";
  }
  &.hb-glyph-bookmark:before {
    content: "\f113";
  }
  &.hb-glyph-buildings:before {
    content: "\f114";
  }
  &.hb-glyph-calendar:before {
    content: "\f115";
  }
  &.hb-glyph-category:before {
    content: "\f116";
  }
  &.hb-glyph-change-duration:before {
    content: "\f117";
  }
  &.hb-glyph-check:before {
    content: "\f118";
  }
  &.hb-glyph-chevron-down:before {
    content: "\f119";
  }
  &.hb-glyph-chevron-left:before {
    content: "\f11a";
  }
  &.hb-glyph-chevron-right:before {
    content: "\f11b";
  }
  &.hb-glyph-chevron-up:before {
    content: "\f11c";
  }
  &.hb-glyph-close:before {
    content: "\f11d";
  }
  &.hb-glyph-copy:before {
    content: "\f11e";
  }
  &.hb-glyph-count:before {
    content: "\f11f";
  }
  &.hb-glyph-cross:before {
    content: "\f120";
  }
  &.hb-glyph-crossed-eye:before {
    content: "\f121";
  }
  &.hb-glyph-detach-window:before {
    content: "\f122";
  }
  &.hb-glyph-dots:before {
    content: "\f123";
  }
  &.hb-glyph-download:before {
    content: "\f124";
  }
  &.hb-glyph-duration:before {
    content: "\f125";
  }
  &.hb-glyph-enlarge:before {
    content: "\f126";
  }
  &.hb-glyph-episode-cluster-mode:before {
    content: "\f127";
  }
  &.hb-glyph-extra-strip:before {
    content: "\f128";
  }
  &.hb-glyph-eye:before {
    content: "\f129";
  }
  &.hb-glyph-filters-off:before {
    content: "\f12a";
  }
  &.hb-glyph-filters-on:before {
    content: "\f12b";
  }
  &.hb-glyph-flag:before {
    content: "\f12c";
  }
  &.hb-glyph-folder:before {
    content: "\f12d";
  }
  &.hb-glyph-gear:before {
    content: "\f12e";
  }
  &.hb-glyph-hidden-folder:before {
    content: "\f12f";
  }
  &.hb-glyph-hollowbookmark:before {
    content: "\f130";
  }
  &.hb-glyph-home:before {
    content: "\f131";
  }
  &.hb-glyph-hospital:before {
    content: "\f132";
  }
  &.hb-glyph-invert-signal:before {
    content: "\f133";
  }
  &.hb-glyph-key:before {
    content: "\f134";
  }
  &.hb-glyph-keyboard:before {
    content: "\f135";
  }
  &.hb-glyph-label:before {
    content: "\f136";
  }
  &.hb-glyph-letter:before {
    content: "\f137";
  }
  &.hb-glyph-list-dots:before {
    content: "\f138";
  }
  &.hb-glyph-list:before {
    content: "\f139";
  }
  &.hb-glyph-loader:before {
    content: "\f13a";
  }
  &.hb-glyph-magnifying-glass:before {
    content: "\f13b";
  }
  &.hb-glyph-medical-bag:before {
    content: "\f13c";
  }
  &.hb-glyph-merge:before {
    content: "\f13d";
  }
  &.hb-glyph-minus:before {
    content: "\f13e";
  }
  &.hb-glyph-moon:before {
    content: "\f13f";
  }
  &.hb-glyph-multiple-files-user:before {
    content: "\f140";
  }
  &.hb-glyph-notification:before {
    content: "\f141";
  }
  &.hb-glyph-patient-activity:before {
    content: "\f142";
  }
  &.hb-glyph-patient-age:before {
    content: "\f143";
  }
  &.hb-glyph-patient-symptom:before {
    content: "\f144";
  }
  &.hb-glyph-pen:before {
    content: "\f145";
  }
  &.hb-glyph-pin:before {
    content: "\f146";
  }
  &.hb-glyph-plus:before {
    content: "\f147";
  }
  &.hb-glyph-printer:before {
    content: "\f148";
  }
  &.hb-glyph-quality-control:before {
    content: "\f149";
  }
  &.hb-glyph-question:before {
    content: "\f14a";
  }
  &.hb-glyph-reclassify:before {
    content: "\f14b";
  }
  &.hb-glyph-reduce:before {
    content: "\f14c";
  }
  &.hb-glyph-reload:before {
    content: "\f14d";
  }
  &.hb-glyph-remove-from-report:before {
    content: "\f14e";
  }
  &.hb-glyph-remove-user:before {
    content: "\f14f";
  }
  &.hb-glyph-report:before {
    content: "\f150";
  }
  &.hb-glyph-revert:before {
    content: "\f151";
  }
  &.hb-glyph-ruler:before {
    content: "\f152";
  }
  &.hb-glyph-share:before {
    content: "\f153";
  }
  &.hb-glyph-shared-orga:before {
    content: "\f154";
  }
  &.hb-glyph-shrink:before {
    content: "\f155";
  }
  &.hb-glyph-single-file-user:before {
    content: "\f156";
  }
  &.hb-glyph-solid-drag:before {
    content: "\f157";
  }
  &.hb-glyph-solid-pediatric:before {
    content: "\f158";
  }
  &.hb-glyph-sort-down:before {
    content: "\f159";
  }
  &.hb-glyph-sort-up:before {
    content: "\f15a";
  }
  &.hb-glyph-sort:before {
    content: "\f15b";
  }
  &.hb-glyph-split-denied:before {
    content: "\f15c";
  }
  &.hb-glyph-split:before {
    content: "\f15d";
  }
  &.hb-glyph-star-filled:before {
    content: "\f15e";
  }
  &.hb-glyph-star:before {
    content: "\f15f";
  }
  &.hb-glyph-status:before {
    content: "\f160";
  }
  &.hb-glyph-stethoscope:before {
    content: "\f161";
  }
  &.hb-glyph-sun:before {
    content: "\f162";
  }
  &.hb-glyph-support:before {
    content: "\f163";
  }
  &.hb-glyph-sync:before {
    content: "\f164";
  }
  &.hb-glyph-time-indicator:before {
    content: "\f165";
  }
  &.hb-glyph-trash:before {
    content: "\f166";
  }
  &.hb-glyph-tunes:before {
    content: "\f167";
  }
  &.hb-glyph-upload:before {
    content: "\f168";
  }
  &.hb-glyph-user-circle:before {
    content: "\f169";
  }
  &.hb-glyph-user:before {
    content: "\f16a";
  }
  &.hb-glyph-warning:before {
    content: "\f16b";
  }
  &.hb-glyph-zoom-area:before {
    content: "\f16c";
  }
}

@mixin hb-icon-xs($name) {
  font-family: "hb-icon-xs" !important;
  content: map.get($hb-icon-xs-glyphs, $name);
}
