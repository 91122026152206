// fix for google fonts embedding on percentual layout
.material-icons {
  font-size: 1.5em;
}

//  --------------------------------------------------
//  Font Icon for cdl-icon
//  --------------------------------------------------

$icomoon-font-family: 'cdl-icon' !default;

$cdl-icon-bolt: '\f101';
$cdl-icon-circle: '\f102';
$cdl-icon-fullscreen-exit: '\f103';
$cdl-icon-grain: '\f104';
$cdl-icon-help-fill: '\f105';
$cdl-icon-plus: '\f106';
$cdl-icon-pointer: '\f107';
$cdl-icon-square: '\f108';
$cdl-icon-symptoms: '\f109';
$cdl-icon-view-ecg: '\f10a';

$icon_font: 'cdl-icon';

@font-face {
  font-family: $icon_font;
  src:
    url('/assets/fonts/cdl-icon.ttf?54fc5841b0bdda1aef48f3d112554980') format('truetype'),
    url('/assets/fonts/cdl-icon.woff?54fc5841b0bdda1aef48f3d112554980') format('woff'),
    url('/assets/fonts/cdl-icon.svg?54fc5841b0bdda1aef48f3d112554980#cdl-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.cdl-icon {
  // use !important to prevent issues with browser extensions that change fonts
  font-family: $icon_font !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  // Better Font Rendering ===========
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &_centered {
    position: absolute;
    top: 0;
    bottom: 0;

    &:before {
      position: relative;
      display: block;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.icon-bolt {
  &:before {
    content: $cdl-icon-bolt;
  }
}

.icon-circle {
  &:before {
    content: $cdl-icon-circle;
  }
}

.icon-fullscreen-exit {
  &:before {
    content: $cdl-icon-fullscreen-exit;
  }
}

.icon-grain {
  &:before {
    content: $cdl-icon-grain;
  }
}

.icon-help-fill {
  &:before {
    content: $cdl-icon-help-fill;
  }
}

.icon-plus {
  &:before {
    content: $cdl-icon-plus;
  }
}

.icon-pointer {
  &:before {
    content: $cdl-icon-pointer;
  }
}

.icon-square {
  &:before {
    content: $cdl-icon-square;
  }
}

.icon-symptoms {
  &:before {
    content: $cdl-icon-symptoms;
  }
}

.icon-view-ecg {
  &:before {
    content: $cdl-icon-view-ecg;
  }
}

$cdl-icons-list: bolt, circle, fullscreen-exit, grain, help-fill, plus, pointer, square, symptoms, view-ecg;
