@use '../../assets/scss/hb-foundations' as *;

$height-extra-small: $font-scale-m;
$height-small: $font-scale-xl;
$height-medium: $font-scale-xxl;

:host {
  white-space: nowrap; // Prevent split button to be on two lines
}

.hb-button {
  --hb-button-border-radius: #{$space-xs};
  &.fully-rounded {
    --hb-button-border-radius: #{$font-scale-xl};
  }

  @include typography(
    $weight-semibold,
    $font-scale-xs,
    1 // Specific here because line-height equals font scale in the spec
  );
  font-family: $font-family-brand;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  border: 0.05rem solid transparent;
  border-radius: var(--hb-button-border-radius);
  transition:
    box-shadow 0.2s ease-out,
    background-color 0.1s ease-out,
    border-color 0.1s ease-out;
  outline: none;

  &.theme-primary,
  &.theme-secondary,
  &.theme-warning {
    margin-right: max(0.05rem, 1px);
  }

  &__left-icon {
    &.margin-right-small {
      margin-right: $space-s;
    }

    &.margin-right-extra-small {
      margin-right: $space-xs;
    }
  }

  &__text {
    &.text-max-width {
      // setting overflow-x will add an unwanted vertical scrollbar
      // https://stackoverflow.com/questions/6179831/setting-overflow-x-hidden-adds-a-vertical-scrollbar
      // we can prevent the vertical scrollbar by setting the
      // height of the span to the height of the button
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(var(--text-max-width) * 1rem);
    }

    &.height {
      &-extra-small {
        height: $height-extra-small; // prevent vertical scrollbar due to overflow-x: hidden;
        // trick to center text vertically found on
        // https://css-tricks.com/centering-css-complete-guide
        // this also allows to keep the ellipsis when the text is too long
        line-height: $height-extra-small;
      }

      &-small {
        height: $height-small;
        line-height: $height-small;
      }

      &-medium {
        height: $height-medium;
        line-height: $height-medium;
      }
    }

    &.theme-quaternary.height-extra-small {
      height: $font-scale-xs;
      line-height: $font-scale-xs;
    }

    &.theme-quaternary.height-small {
      height: $font-scale-s;
      line-height: $font-scale-s;
    }

    &.theme-quaternary.height-medium {
      height: $font-scale-m;
      line-height: $font-scale-m;
    }
  }

  &__right-icon {
    &.margin-left-small {
      margin-left: $space-s;
    }

    &.margin-left-extra-small {
      margin-left: $space-xs;
    }
  }
}

// Splitted button
.splitted {
  vertical-align: top;

  &:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 $space-s;

    &.size-extra-small {
      padding: 0 $space-xs;
    }
  }

  .icon {
    pointer-events: none;
  }
}

.size-medium {
  height: $height-medium;
  padding: 0 $space-m;
}

.size-small {
  height: $height-small;
  padding: 0 #{$space-s + $space-xs};
}

.icon-button {
  padding: 0 $space-s;
}

.size-extra-small {
  height: $height-extra-small;
  padding: 0 #{$space-xs};
  @include typography($weight-semibold, 1rem, 1);
}

// Themes
.theme-primary {
  background-color: $primary-500;
  color: $neutral-0;

  &:hover {
    background-color: $primary-600;
  }

  &:active {
    background-color: $primary-700;
  }

  &:focus-visible {
    background-color: $primary-600;
    box-shadow: $focus-visible-shadow;
  }

  &.modified {
    background-color: $primary-700;
  }

  &:disabled {
    @include native-form-control-disabled-state();
  }
}

.theme-secondary {
  background-color: $neutral-0;
  color: $neutral-900;
  border-color: $neutral-900;

  &:hover,
  &:focus-visible {
    border-color: $primary-500;
    color: $primary-500;
    background-color: $neutral-0;
  }

  &:focus-visible {
    box-shadow: $focus-visible-shadow;
  }

  &:active {
    border-color: $primary-700;
    color: $primary-700;
    background-color: $neutral-0;
  }

  &:disabled {
    @include native-form-control-disabled-state();
    border-color: $neutral-50;
  }
}

.theme-tertiary {
  background-color: transparent;
  color: $neutral-900;

  &:hover,
  &:focus-visible {
    background-color: $neutral-50;
  }

  &:focus-visible {
    box-shadow: $focus-visible-shadow;
  }

  &:active {
    background-color: $neutral-100;
  }

  .modified {
    color: $primary-500;
  }

  &:disabled {
    @include native-form-control-disabled-state();
  }
}

.theme-quaternary {
  background-color: transparent;
  color: $primary-500;
  font-weight: 400;
  padding: 0;
  height: auto;

  &.hb-button__text {
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  &:focus-visible {
    box-shadow: $focus-visible-shadow;
  }

  &:disabled {
    @include native-form-control-disabled-state();
    background-color: transparent;
    text-decoration: none;
  }
}

.theme-warning {
  background-color: $red-500;
  color: $neutral-0;

  &:hover {
    background-color: $red-600;
  }

  &:focus-visible {
    box-shadow: 0rem 0rem 0rem 0.36rem $red-100;
  }

  &:active {
    background-color: $red-700;
  }

  &:disabled {
    @include native-form-control-disabled-state();
  }
}

.theme-warning-reversed {
  background-color: transparent;
  color: $red-500;

  &:hover {
    background-color: $neutral-50;
  }

  &:focus-visible {
    box-shadow: 0rem 0rem 0rem 0.36rem $red-100;
    background-color: $neutral-50;
  }

  &:active {
    background-color: $neutral-100;
  }

  &.modified {
    background-color: $red-500;
    color: $neutral-0;
  }

  &:disabled {
    @include native-form-control-disabled-state();
  }
}

.theme-success {
  background-color: transparent;
  color: $green-500;

  &:hover {
    background-color: $neutral-50;
  }

  &:focus-visible {
    box-shadow: 0rem 0rem 0rem 0.36rem $green-100;
    background-color: $neutral-50;
  }

  &:active {
    background-color: $neutral-100;
  }

  &.modified {
    background-color: $green-500;
    color: $neutral-0;
  }

  &:disabled {
    @include native-form-control-disabled-state();
  }
}

.theme-dark {
  background-color: $neutral-700;
  color: $neutral-0;

  &:hover {
    background-color: $neutral-600;
  }

  &:focus-visible {
    box-shadow: $focus-visible-shadow;
  }

  &:active {
    background-color: $neutral-500;
  }

  .modified {
    color: $primary-300;
  }

  &:disabled {
    @include native-form-control-disabled-state();
  }
}
