// Shadows: https://zeroheight.com/94b85b49d/p/68ef1a-shadows
@use 'sass:map';

@use '@cdl/heartbeat-tokens/dist/holter' as *;

$shadow-200: map.get($shadow, 200);
$shadow-400: map.get($shadow, 400);
$shadow-500: map.get($shadow, 500);
$shadow-600: map.get($shadow, 600);
$focus-visible-shadow: map.get($shadow, 'focus-visible-shadow');
$focus-visible-shadow-invalid: map.get($shadow, 'focus-visible-shadow-invalid');

// Product specifics
$shadow-episode-highlighted: -0.4rem 0 0 map.get($colors-primary, 500);
