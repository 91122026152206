@mixin fontFace($name, $weight: 400, $style: inherit) {
  @font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/open-sans/OpenSans-#{$name}.ttf');
    font-weight: $weight;
    font-style: $style;
  }
}

// Light
@include fontFace('Light', 300);

// Regular
@include fontFace('Regular', 400);
@include fontFace('Italic', 400, italic);

// Semibold
@include fontFace('SemiBold', 600);
@include fontFace('SemiBoldItalic', 600, italic);

// Bold
@include fontFace('Bold', 700);
@include fontFace('BoldItalic', 700, italic);

/** Frutiger */

/* Regular */
@font-face {
  font-family: 'Neue Frutiger One';
  src: url('/assets/fonts/neue-frutiger/NeueFrutigerOne-Regular.ttf');
  font-weight: 400;
  font-style: inherit;
  font-display: swap;
}

/* Bold */
@font-face {
  font-family: 'Neue Frutiger One';
  src: url('/assets/fonts/neue-frutiger/NeueFrutigerOne-Bold.ttf');
  font-weight: 600;
  font-style: inherit;
  font-display: swap;
}
