@use 'sass:math';
@use 'layout' as *;
@use 'text' as *;
@use '../config/variables' as *;
@use 'libs/heartbeat/src/lib/assets/scss/hb-foundations/colors' as *;

.input {
  @extend .label;
  width: 20rem;
  box-sizing: border-box;
  padding: 0 $padding-s;
  border-radius: $radius-m;
  height: 3rem;
  line-height: 1.5rem;

  &_light {
    color: $neutral-700;
    background-color: $neutral-0;

    border: $border-xs solid $neutral-100;

    &:focus {
      @extend %override-outline;
    }

    &:invalid {
      box-shadow: inset 0 0 0.3rem 0 $red-500;
      border: $border-xs solid rgba($red-500, 66);
    }

    &:disabled {
      background-color: $neutral-25;
    }

    &::placeholder {
      color: $neutral-400;
      font-size: $font-s;
      opacity: 1;
    }
  }

  &_dark {
    border: $border-xs solid $neutral-900;
    background-color: $neutral-600;
    color: $neutral-0;

    &:focus {
      @extend %override-outline;
    }

    &:invalid {
      box-shadow: inset 0 0 0.3rem 0 $red-500;
      border: $border-xs solid rgba($red-500, 66);
    }

    &:disabled {
      background-color: $neutral-25;
    }

    &::placeholder {
      color: $neutral-300;
      font-size: $font-s;
      opacity: 1;
    }
  }

  &_big {
    height: 4rem;
  }

  &_error {
    border: $border-xs solid $red-500;
  }

  &.validation-error {
    border-color: $red-500;
    box-shadow: none;
  }

  &.validation-warning {
    border-color: $yellow-500;
    box-shadow: none;
  }

  &.validation-success {
    border-color: $green-500;
    box-shadow: none;
  }
}

$small-height: 100%;
.input-small {
  @extend .input;
  height: $small-height !important;
}

.text-area {
  &_light {
    width: 100%;
    height: 6rem;
    color: $neutral-700;
    background-color: $neutral-0;
    padding: $padding-s + $padding-xs;
    border: $border-xs solid $neutral-100;
    border-radius: $radius-m;

    &:focus {
      @extend %override-outline;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      color: transparent;
      font-size: $font-xxs;
    }

    &_error {
      border: $border-xs solid $red-500;
    }
  }
}

.button {
  @extend .label;
  @extend .label_dark_primary;

  padding: 0.8rem 1.2rem;
  border: 0.1rem solid $primary-500;
  border-radius: $radius-m;
  background-color: $primary-500;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  color: $neutral-0;
  font-size: $font-s;
  display: inline-block;

  &:hover {
    color: $primary-500;
    border-color: $primary-500;
    background-color: transparent;
  }

  &:active {
    color: $neutral-0;
    border-color: $primary-500;
    background-color: $primary-500;
  }

  &:disabled {
    opacity: 0.5;
    color: $neutral-300;
    border-color: $neutral-300;
    background-color: transparent;
    cursor: not-allowed;
  }

  &.plain {
    font-weight: $font-weight-thin;

    &:hover {
      background: $primary-300;
      color: $neutral-0;
    }
  }

  &.icon {
    display: flex;
    align-items: center;

    em,
    .cdl-icon {
      margin-right: $padding-xxs;
    }
  }

  &_small {
    @extend .label;
    @extend .label_light_primary;
    @extend .label_light_primary_small;
    font-size: $font-m;
    padding: $padding-xs $padding-s;
    white-space: nowrap;
  }

  &_xsmall {
    @extend .label;
    @extend .label_light_primary;
    @extend .label_light_primary_small;
    font-size: $font-xs;
    padding: $padding-xxs $padding-s;
    white-space: nowrap;
  }

  &_light {
    color: $neutral-700;
    border-color: $neutral-100;
    background-color: $neutral-25;

    a {
      color: $neutral-700;
    }

    &:hover {
      color: $neutral-700;
      border-color: $neutral-300;
      background-color: $neutral-50;

      a {
        color: $neutral-700;
      }
    }

    &:active {
      color: $neutral-700;
      border-color: $neutral-300;
      background-color: $neutral-50;

      a {
        color: $neutral-700 !important;
      }
    }
  }

  &_primary_cancel {
    @extend .label;
    @extend .label_light_primary;
    @extend .label_light_primary_small;

    background-color: $red-500;
    border: $border-xs solid $red-500;
    color: $neutral-0;

    &:hover {
      color: $red-500;
      background-color: $neutral-0;
      border: $border-xs solid $red-500;
    }

    &:active {
      background-color: $red-500;
      border: $border-xs solid $red-500;
      color: $neutral-0;
    }
  }

  &_cancel {
    @extend .label;
    @extend .label_light_primary;
    @extend .label_light_primary_small;

    background-color: transparent;
    border: $border-xs solid $red-500;
    color: $red-500;

    &:hover {
      color: $neutral-0;
      background-color: $red-500;
      border: $border-xs solid $red-500;
    }

    &:active {
      background-color: transparent;
      border: $border-xs solid $red-500;
      color: $red-500;
    }
  }

  &__toggle {
    $button-size: 1.4rem;
    position: absolute;
    margin-left: -9999rem;
    visibility: hidden;

    & + label {
      display: block;
      position: relative;
      cursor: pointer;
      outline: none;
      user-select: none;
      padding: 0.2rem;
      width: $button-size * 2;
      height: $button-size;
      background-color: #dddddd;
      border-radius: $button-size;

      &:before,
      &:after {
        display: block;
        position: absolute;
        top: 0.1rem;
        left: 0.1rem;
        bottom: 0.1rem;
        content: '';
      }

      &:before {
        right: 0.1rem;
        background-color: $neutral-25;
        border-radius: $button-size;
        transition: background 0.4s;
      }

      &:after {
        width: $button-size * 0.9;
        background-color: $neutral-0;
        border-radius: 100%;
        box-shadow: 0 0.2rem 0.4rem rgba($neutral-900, 0.3);
        transition: margin 0.4s;
      }
    }

    &:checked + label {
      &:before {
        background-color: $primary-300;
      }

      &:after {
        margin-left: $button-size;
      }
    }
  }
}

.button-reset {
  font-size: 100%;
  font-family: inherit;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
}

.progress {
  // Reset the default appearance
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 8.4rem;
  height: $size-s;

  background-color: $neutral-25;
  border-radius: $radius-m;
  border: none;

  &::-webkit-progress-bar {
    background-color: $neutral-25;
    border-radius: $radius-m;
  }

  &::-webkit-progress-value {
    background-color: $neutral-900;
    border-radius: $radius-m;
    background-size:
      $size-l $size-ml,
      100% 100%,
      100% 100%;
  }

  &::-moz-progress-bar {
    background-color: $neutral-700;
    border-radius: $radius-m;
    background-size:
      $size-l $size-ml,
      100% 100%,
      100% 100%;
  }
}

.box-shadow {
  &__card-1 {
    box-shadow:
      0 1px 3px rgba($neutral-900, 0.12),
      0 1px 2px rgba($neutral-900, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  &__card-1:hover {
    box-shadow:
      0 14px 28px rgba($neutral-900, 0.25),
      0 10px 10px rgba($neutral-900, 0.22);
  }

  &__card-2 {
    box-shadow: 0 2rem 1rem -2rem rgba($neutral-900, 0.05);
  }

  &__card-3 {
    box-shadow:
      0 10px 20px rgba($neutral-900, 0.19),
      0 6px 6px rgba($neutral-900, 0.23);
  }

  &__card-4 {
    box-shadow:
      0 14px 28px rgba($neutral-900, 0.25),
      0 10px 10px rgba($neutral-900, 0.22);
  }

  &__card-5 {
    box-shadow:
      0 19px 38px rgba($neutral-900, 0.3),
      0 15px 12px rgba($neutral-900, 0.22);
  }
}

// disable selection on text
.disable-user-selection {
  -webkit-user-select: none; // Chrome/Safari
  -moz-user-select: none; // Firefox
  -ms-user-select: none; // IE10+

  // Rules below not implemented in browsers yet
  -o-user-select: none;
  user-select: none;
}

.radio-button {
  display: none;

  + label {
    $size: $size-sm;

    cursor: pointer;
    position: relative;
    margin-left: $size;
    padding: 0 $padding-sm 0 $padding-s;

    &:before {
      width: $size;
      height: $size;
      border-radius: $size;
      position: absolute;
      top: calc(50% - #{math.div($size, 2)});
      left: -$size;
      background-color: $neutral-100;
      visibility: visible;
      content: '';
    }
  }

  &:checked + label:before {
    background-color: $primary-300;
  }
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: ($padding-m + $padding-s);
  margin-bottom: $margin-sm;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & > input {
    position: absolute;
    opacity: 0;
  }

  &:hover > input ~ .checkbox:after {
    left: 0.4rem;
    top: 0;
    width: 0.3rem;
    height: 0.8rem;
    border: solid $neutral-100;
    border-width: 0 $border-s $border-s 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:hover > .checkbox {
    &:after {
      content: '';
      position: absolute;
      display: block;
    }
  }

  & > input:checked ~ .checkbox:after {
    display: block;
    border-color: $neutral-300;
  }

  &_emergency {
    & > input:checked ~ .checkbox:after {
      border-color: $red-500;
    }
  }

  &_normal {
    & > input:checked ~ .checkbox:after {
      border-color: $green-300;
    }
  }

  &_significant {
    & > input:checked ~ .checkbox:after {
      border-color: $yellow-400;
    }
  }

  &_non-significant {
    & > input:checked ~ .checkbox:after {
      border-color: $primary-300;
    }
  }

  & > .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.4rem;
    width: 1.4rem;
    background-color: $neutral-0;
    border: solid $border-xs $neutral-100;
    border-radius: $radius-m;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 0.4rem;
      top: 0;
      width: 0.3rem;
      height: 0.8rem;
      border: solid $neutral-100;
      border-width: 0 $border-s $border-s 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

// hide clear type=radio
input[type='radio'] {
  visibility: hidden;
  height: 100%;
  width: 100%;
}

// hide clear and spin button of type=date
input[type='date'],
input[type='time'] {
  &::-webkit-search-cancel-button,
  &::-webkit-clear-button,
  &::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
  }
}

:root {
  --cdl-tooltip-offset: 0rem;
}

.tooltip {
  &_container {
    position: relative !important;
    display: inline-block;
    overflow: hidden;
  }

  &_label {
    visibility: hidden;
    width: max-content;
    max-width: 20rem;
    height: min-content;
    background-color: $neutral-900;
    color: $neutral-0;
    text-align: center;
    padding: $padding-xs $padding-s;
    border-radius: $radius-m;
    filter: drop-shadow(0 0 0.4rem rgba($neutral-900, 0.3));
    font-size: $font-xxs;
    // pointer-events: none; // TODO: uncomment if twerking pixel occurs, but fix tooltip not disappearing first

    // Position the tooltip text
    position: absolute;

    // Fade in tooltip
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 99;

    &::after {
      z-index: 100;
      content: '';
      position: absolute;
      margin-left: -$border-m;
      border: $border-m solid;
      border-color: $neutral-900 transparent transparent transparent;
    }

    &_right::after {
      top: calc(50% + var(--cdl-tooltip-offset));
      left: 0.05rem; // 0.9vw hack :'(
      right: unset;
      transform: rotate(90deg) translate3d(-50%, 50%, 0);
    }

    &_left::after {
      top: calc(50% + var(--cdl-tooltip-offset));
      left: unset;
      right: 0.05rem; // 0.9vw hack :'(
      transform: rotate(-90deg) translate3d(50%, 100%, 0);
    }

    &_top::after {
      top: calc(100% - 0.05rem);
      bottom: unset;
      left: calc(50% + var(--cdl-tooltip-offset));
    }

    &_bottom::after {
      bottom: calc(100% - 0.05rem);
      top: unset;
      left: calc(50% + var(--cdl-tooltip-offset));
      transform: rotate(180deg);
    }
  }

  &_hover {
    visibility: visible;
    opacity: 1;
  }

  &_break {
    word-break: break-word;
  }

  &_mouse {
    pointer-events: none;
    position: fixed;
    top: -1rem;
    left: 0;
    transition: opacity 0.3s ease-in-out;
  }
}

.cdl-top-bar {
  box-shadow: 0 0.2rem 0.9rem 0 rgba($neutral-900, 0.05);
  border-bottom: $border-xxs solid $neutral-100;
  background-color: $neutral-0;
  padding: 0 $padding-m;
  height: 3rem;
}

// Forcing virtual scroll contain to default because "content" was preventing
// our component dropdown to be shown when overflowing the parent
body .cdk-virtual-scroll-content-wrapper {
  contain: none;
}

// Forcing virtual scroll contain to none in onboarding mode because it's blocking z-index
// for focused
.onboardingActive cdk-virtual-scroll-viewport {
  contain: none;
  transform: none !important;
  overflow: hidden !important;
}

.onboardingActive .cdk-virtual-scroll-content-wrapper {
  contain: none;
  transform: none !important;
  overflow: hidden !important;
}
