@mixin blockShadow() {
  @include box-shadow(0 0.1rem 0.4rem rgba($neutral-900, 0.35));
}

@mixin applyFont($fontName, $fontPath) {
  @font-face {
    font-family: '#{$fontName}';
    src: url('../fonts/#{$fontPath}/#{$fontName}.eot');
    src:
      url('../fonts/#{$fontPath}/#{$fontName}.eot?#iefix') format('embedded-opentype'),
      url('../fonts/#{$fontPath}/#{$fontName}.woff') format('woff'),
      url('../fonts/#{$fontPath}/#{$fontName}.ttf') format('truetype'),
      url('../fonts/#{$fontPath}/#{$fontName}.svg##{$fontName}') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin infiniteScrollHeight($subtract) {
  max-height: calc(100vh - #{$subtract});
  overflow-y: auto;
  overflow-x: hidden;
}

@mixin flexVertical() {
  display: flex;
  flex-direction: column;
}

@mixin flexHorizontal() {
  display: flex;
  flex-direction: row;
}
