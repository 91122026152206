@use 'config/variables' as *;
@use 'libs/heartbeat/src/lib/assets/scss/hb-foundations/colors' as *;

input[type='range'] {
  -webkit-appearance: none;
  width: 75px;
  display: inline-block;
  margin-left: 7px;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 6.4px;
  cursor: pointer;
  background: #3071a9; // TODO: no matching color
  border-radius: 1.3px;
  border: 0.2px solid $color-blue;
}

input[type='range']::-webkit-slider-thumb {
  border: 1px solid $neutral-900;
  height: 25px;
  width: 7px;
  background: $neutral-0;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: $color-blue;
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow:
    1px 1px 1px $neutral-900,
    0px 0px 1px $neutral-900;
  background: $color_blue;
  border-radius: 1.3px;
  border: 0.2px solid $neutral-900;
}

input[type='range']::-moz-range-thumb {
  box-shadow:
    1px 1px 1px $neutral-900,
    0px 0px 1px $neutral-900;
  border: 1px solid $neutral-900;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: $neutral-0;
  cursor: pointer;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type='range']::-ms-fill-lower {
  background: $cdl-primary;
  border: 0.2px solid $neutral-900;
  border-radius: 2.6px;
  box-shadow:
    1px 1px 1px $neutral-900,
    0px 0px 1px $neutral-900;
}

input[type='range']::-ms-fill-upper {
  background: #3071a9; // TODO: no matching color
  border: 0.2px solid $neutral-900;
  border-radius: 2.6px;
  box-shadow:
    1px 1px 1px $neutral-900,
    0px 0px 1px $neutral-900;
}

input[type='range']::-ms-thumb {
  box-shadow:
    1px 1px 1px $neutral-900,
    0px 0px 1px $neutral-900;
  border: 1px solid $neutral-900;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: $neutral-0;
  cursor: pointer;
}

input[type='range']:focus::-ms-fill-lower {
  background: #3071a9; // TODO: no matching color
}

input[type='range']:focus::-ms-fill-upper {
  background: #367ebd; // TODO: no matching color
}
